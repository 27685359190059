<template>

  <div class="inner way">
    <div class="flex-none p-4 border-b">
      <div class="flex justify-between items-center">
        <strong class="text-xl font-medium">
          Directions
        </strong>
      </div>
      <div class="justify-between font-medium">
        <div class="block text-lg text-orange-dark">
          {{ secondsToDhms() }}
          <span class="text-lg text-grey-dark">{{ parsedDistance.m }} miles ({{ parsedDistance.k }} km)</span>
        </div>
        <div class="text-xs pt-1 text-grey-dark">
          Vehicle Dimensions:
          H:{{ vehicles_dimensions.height.val }}m
          W:{{ vehicles_dimensions.width.val }}m
          L:{{ vehicles_dimensions.length.val }}m
          Wt:{{ vehicles_dimensions.weight.val }}t
        </div>
      </div>
    </div>
    <div class="actions save flex items-center justify-between gap-10p ">
      <button class="w_100 white-green" @click="editRoute()">Edit</button>
      <button class="w_100 green-white" @click="saveRoute">Save</button>
<!--      <button class="w_100 green-white" @click="share()">Save & Share</button>-->
    </div>
    <div class="actions selest-route flex items-center justify-between gap-10p pt-5" v-if='route_data.routes.length > 1'>
      <button class="w_100"
              :class='{"green-white": route.id === selected_route_id,
              "white-green": route.id !== selected_route_id}'
              v-for="(route, index) in route_data.routes"
              :key="route.id"
              @click="chooseRoute(route.id)">option {{index+1}}</button>
<!--      <button class="w_100 green-white" @click="share()">Save & Share</button>-->
    </div>
    <RouteInstructions
        v-if="route_data.routes[selected_route_index].sections"
      :sections="route_data.routes[selected_route_index].sections"
    />

  </div>
</template>
<script>

import RouteInstructions from "@/components/plan-elements/RouteInstructions.vue";

export default {
  name: 'SaveRoute',
  components: {RouteInstructions},
  props: {
    route_data: {
      type: Object,
    },
    vehicles_dimensions: {
        type: Object,
        default: () => {
          return {
            width: {
              min: 1,
              max: 6,
              val: 2.5,
              step: 0.1,
              name: 'Width',
              dimention: 'm'
            },
            height: {
              min: 1,
              max: 10,
              val: 4,
              step: 0.1,
              name: 'Height',
              dimention: 'm'
            },
            length: {
              min: 1,
              max: 50,
              val: 12,
              step: 0.5,
              name: 'Length',
              dimention: 'm'
            },
            weight: {
              min: 1,
              max: 50,
              val: 18,
              step: 1,
              name: 'Weight',
              dimention: 't'
            },
          }
        }
    }
  },
  data() {
    return {
      route_name: '',
      colors: ['#FF0000', '#00FF00'],
      selected_route_id: 0,
      selected_route_index: 0,
      parsedDistance: {
        k: null,
        m: null
      },
    }
  },
  methods: {
    editRoute() {
      this.$emit('edit_route', true)
    },
    async chooseRoute(route_id) {
      this.selected_route_id = route_id;
      this.selected_route_index = this.route_data.routes.findIndex(route => route.id === route_id);
      await this.$emit('choose_route', route_id)
    },
    saveRoute() {
      this.$emit('save_route', true)
    },
    share() {
      this.$emit('share_route')
    },

    secondsToDhms() {
      const seconds = this.route_data.routes[this.selected_route_index].sections.reduce((acc, section) => {
        return acc + section.travelSummary.duration;
      }, 0);
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);

      var dDisplay = d > 0 ? d + (" d, ") : "";
      var hDisplay = h > 0 ? h + (" h, ") : "";
      var mDisplay = m > 0 ? m + ("min") : "";
      return dDisplay + hDisplay + mDisplay;
    },
    async parseDistance() {

      const travelLength = this.route_data.routes[this.selected_route_index].sections.reduce((acc, section) => {
        return acc + section.travelSummary.length;
      }, 0);
      this.selected_route_id = this.route_data.routes[this.selected_route_index].id;
      this.parsedDistance.k = Math.round(travelLength / 1000);
      this.parsedDistance.m = Math.round((travelLength / 1000) / 1.609344);
    },
    distanceToString(meters) {
      if(meters)
        return `${Math.round(meters / 1000)} Km (${Math.round((meters / 1000) / 1.609344)} miles)`;
      else return ''
    },
  },
  async mounted() {
    await this.parseDistance();

  }
}
</script>


<style scoped lang="scss">
.actions {
  button {
    height: 46px;
    &.white-green {
      height: 44px;
    }
  }
}
</style>